import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/api/methods/authentication.service';
import { Project } from '../../../entities/project.entity';
import { SalesDashboardService } from '../../../services/api/methods/sales-dashboard.service';
import { SalesTimelineItem } from '../../../entities/sales/sales-timeline-item.entity';
import { CampaignRuleTrackingTypes } from '../../../entities/campaigns/campaign-rule.entity';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sales-timeline',
  templateUrl: './sales-timeline.component.html',
  styleUrls: ['./sales-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalesTimelineComponent implements OnInit, OnChanges {
  public project!: Project;

  @Input('milestones') public milestones: SalesTimelineItem[] | null = null;
  @Input('guestDetailMode') public singleGuestMode: boolean = false;

  @Output('deleteMilestone') public $deleteMilestone: EventEmitter<SalesTimelineItem> = new EventEmitter<SalesTimelineItem>();

  @Input('debugModeActivated') public debugModeActivated: boolean = false;
  public env = environment;

  public CampaignRuleTrackingTypes = CampaignRuleTrackingTypes;

  public ngOnInit(): void {
    this.project = this.route.snapshot.data.project;
    this.cdr.markForCheck();
  }

  public ngOnChanges() {
    this.cdr.markForCheck();
  }

  public getFormattedMilestoneDate(milestone: SalesTimelineItem) {
    const date: Date = parseISO(milestone.created_at);

    return formatDistanceToNow(date, { includeSeconds: false, addSuffix: true });
  }

  public deleteMilestone(milestone: SalesTimelineItem) {
    this.$deleteMilestone.emit(milestone);
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private salesService: SalesDashboardService,
    private cdr: ChangeDetectorRef
  ) {}
}
