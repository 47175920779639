<span class="d-block w-100 text-center" *ngIf="milestones === null">
  <i class="fa fa-2x fa-spinner fa-spin text-muted"></i>
</span>

<div class="timeline" *ngIf="milestones !== null">
  <div *ngIf="milestones.length === 0">
    <div class="text-muted">Sorry - currently no milestones available!</div>
  </div>
  <div *ngFor="let timelineItem of milestones; last as isLast" class="timeline-container" [class.last]="isLast">
    <ng-container
      *ngIf="!timelineItem.joined_campaign_via_milestone"
      [ngTemplateOutlet]="defaultTimelineItem"
      [ngTemplateOutletContext]="{ item: timelineItem }"
    ></ng-container>
    <ng-container *ngIf="timelineItem.joined_campaign_via_milestone">
      <div class="timeline-icon funnel-join">
        <i class="fas fa-user-plus"></i>
      </div>
      <div class="timeline-content">
        <p class="main">
          <ng-container
            *ngIf="!singleGuestMode"
            [ngTemplateOutlet]="guestName"
            [ngTemplateOutletContext]="{ guest: timelineItem.guest }"
          ></ng-container>
          <span>{{ singleGuestMode ? 'Started' : ' started' }} funnel with </span>
          <ng-container [ngSwitch]="timelineItem.tracking_type">
            <span *ngSwitchCase="CampaignRuleTrackingTypes.eventBooked">event booking</span>
            <span *ngSwitchCase="CampaignRuleTrackingTypes.meetingBooked">meeting booking</span>
            <span *ngSwitchCase="CampaignRuleTrackingTypes.pageView">page view</span>
            <span *ngSwitchCase="CampaignRuleTrackingTypes.audienceJoined">joining an audience</span>
            <span *ngSwitchCase="CampaignRuleTrackingTypes.formSubmitted">form submission</span>
            <span *ngSwitchDefault>registration</span>
          </ng-container>
        </p>
        <p class="text-muted">
          {{ timelineItem.created_at }}
        </p>
      </div>
      <div class="timeline-spacer"></div>
    </ng-container>
  </div>
</div>

<ng-template #defaultTimelineItem let-item="item">
  <div
    class="timeline-icon"
    [class.page-view]="item.tracking_type === CampaignRuleTrackingTypes.pageView"
    [class.event-booking]="item.tracking_type === CampaignRuleTrackingTypes.eventBooked"
    [class.meeting-booking]="item.tracking_type === CampaignRuleTrackingTypes.meetingBooked"
    [class.form-submit]="item.tracking_type === CampaignRuleTrackingTypes.formSubmitted"
    [class.audience-join]="item.tracking_type === CampaignRuleTrackingTypes.audienceJoined"
    [class.comment]="item.tracking_type === CampaignRuleTrackingTypes.comment"
    [class.sale]="item.tracking_type === CampaignRuleTrackingTypes.sale"
  >
    <i
      class="fas fa-lg"
      [class.fa-sticky-note]="item.tracking_type === CampaignRuleTrackingTypes.pageView"
      [class.fa-calendar]="item.tracking_type === CampaignRuleTrackingTypes.eventBooked"
      [class.fa-comments]="item.tracking_type === CampaignRuleTrackingTypes.meetingBooked"
      [class.fa-dollar-sign]="item.tracking_type === CampaignRuleTrackingTypes.sale"
      [class.fa-info]="item.tracking_type === CampaignRuleTrackingTypes.comment"
      [class.fa-align-justify]="item.tracking_type === CampaignRuleTrackingTypes.formSubmitted"
      [class.fa-users]="item.tracking_type === CampaignRuleTrackingTypes.audienceJoined"
    ></i>
  </div>
  <div class="timeline-content">
    <p class="main">
      <ng-container
        *ngIf="!singleGuestMode"
        [ngTemplateOutlet]="guestName"
        [ngTemplateOutletContext]="{ guest: item.guest }"
      ></ng-container>
      <ng-container [ngSwitch]="item.tracking_type">
        <span *ngSwitchCase="CampaignRuleTrackingTypes.eventBooked"
          >{{
            singleGuestMode ? (item.effective_score > 0 ? 'Booked' : 'Cancelled') : item.effective_score > 0 ? ' booked' : ' cancelled'
          }}
          an Event<span *ngIf="item.source_name !== null"> "{{ item.source_name }}"</span></span
        >
        <span *ngSwitchCase="CampaignRuleTrackingTypes.meetingBooked"
          >{{
            singleGuestMode ? (item.effective_score > 0 ? 'Booked' : 'Cancelled') : item.effective_score > 0 ? ' booked' : ' cancelled'
          }}
          a Meeting<span *ngIf="item.source_name !== null"> "{{ item.source_name }}"</span></span
        >
        <span *ngSwitchCase="CampaignRuleTrackingTypes.pageView"
          >{{ singleGuestMode ? 'Viewed' : ' viewed' }} Page "{{ item.source_name }}"</span
        >
        <span *ngSwitchCase="CampaignRuleTrackingTypes.audienceJoined">
          {{
            singleGuestMode
              ? item.tracking_event_type === 'audienceMemberAdded'
                ? 'Join'
                : 'Left'
              : item.tracking_event_type === 'audienceMemberAdded'
              ? ' join'
              : ' left'
          }}
          Audience "{{ item.source_name }}"
        </span>
        <span *ngSwitchCase="CampaignRuleTrackingTypes.formSubmitted"
          >{{ singleGuestMode ? 'Submitted' : ' submitted' }} Form "{{ item.source_name }}"</span
        >
        <span *ngSwitchCase="CampaignRuleTrackingTypes.comment"
          >{{ singleGuestMode ? 'Received comment' : ' received comment' }}: {{ item.comment }}</span
        >
        <span *ngSwitchCase="CampaignRuleTrackingTypes.sale">{{ singleGuestMode ? 'Bought' : ' bought' }}: {{ item.comment }}</span>
        <span *ngIf="debugModeActivated && (env.envName === 'stage' || env.envName === 'local')"
          ><br />(DBG Info: {{ item.tracking_event_type }})</span
        >
      </ng-container>
      <ng-container
        *ngIf="
          singleGuestMode &&
          (item.tracking_type === CampaignRuleTrackingTypes.comment || item.tracking_type === CampaignRuleTrackingTypes.sale)
        "
      >
        <button (click)="deleteMilestone(item)" class="float-right border-0 bg-transparent">
          <i class="fas fa-trash-alt text-muted"></i>
        </button>
      </ng-container>
      <span
        *ngIf="!(item.tracking_type === CampaignRuleTrackingTypes.comment || item.tracking_type === CampaignRuleTrackingTypes.sale)"
        class="d-inline-block float-right text-muted"
      >
        {{ item.effective_score > 0 ? '+' : '-' }}{{ item.effective_score }} P
      </span>
    </p>
    <p class="text-muted">
      {{ getFormattedMilestoneDate(item) }}
      <span *ngIf="singleGuestMode && item.tracking_type === CampaignRuleTrackingTypes.sale" class="float-right">{{
        item.amount / 100 | currency: item.currency:'symbol':'1.0-0'
      }}</span>
    </p>
  </div>
  <div class="timeline-spacer"></div>
</ng-template>

<ng-template #guestName let-guest="guest">
  <span *ngIf="guest?.custom_first_name !== null || guest?.custom_last_name !== null" class="font-weight-bold"
    >{{ guest?.custom_first_name }} {{ guest?.custom_last_name }}</span
  >
  <span *ngIf="guest?.custom_first_name === null && guest?.custom_last_name === null" class="font-weight-bold">A Guest</span>
</ng-template>
