import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ArrayFindByUuidPipe } from './array-find-by-uuid.pipe';
import { GetMapPipe } from './get-map.pipe';
import { IntegerRangePipe } from './integer-range.pipe';
import { IsArrayPipe } from './is-array.pipe';
import { KeysPipe } from './keys.pipe';
import { PluckPipe } from './pluk.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafePipe } from './safe.pipe';
import { TruncateStringPipe } from './truncate-string.pipe';
import { UcFirstPipe } from './uc-first.pipe';
import { MatchValueWithPossibleFieldValuesPipe } from './match-value-with-possible-field-values.pipe';

const pipes = [
  KeysPipe,
  UcFirstPipe,
  SafePipe,
  TruncateStringPipe,
  IntegerRangePipe,
  PluckPipe,
  SafeHtmlPipe,
  GetMapPipe,
  IsArrayPipe,
  ArrayFindByUuidPipe,
  MatchValueWithPossibleFieldValuesPipe
];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
  providers: [...pipes]
})
export class SharedPipesModule {}
