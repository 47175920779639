export const environment = {
  production: true,
  envName: 'prod',
  header: {
    logo: 'logo.png'
  },
  api: {
    endpoint: 'https://api.hello-one.de/api/'
  },
  pusher: {
    public_key: 'e5aa08d596ec30633463'
  }
};
