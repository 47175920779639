<div class="card">
  <div class="custom-card-img-top">
    <div class="custom-card-img-container">
      <img
        src="{{ guest.profile_image_url ? guest.profile_image_url : 'assets/images/guest_image_placeholder.png' }}"
        alt="Guest Profile"
      />
    </div>
  </div>

  <div class="card-body">
    <h4 class="card-title" *ngIf="guest.custom_first_name_field || guest.custom_last_name_field">
      <span class="mb-2 mb-xl-0" *ngIf="guest.custom_first_name_field">{{ guest.custom_first_name_field?.value }} </span
      ><span *ngIf="guest.custom_last_name_field">{{ guest.custom_last_name_field.value }}</span>
      <i
        *ngIf="guest.email_verified && (guest.custom_first_name_field || guest.custom_last_name_field)"
        class="fas fa-check-circle ml-2"
        title="Email verified at {{ guest.email_verified_at | date: 'dd/MM/yyyy, hh:mm' }}"
      ></i>
    </h4>

    <p class="card-text">
      {{ guest.email
      }}<i
        *ngIf="guest.email_verified && !(guest.custom_first_name_field || guest.custom_last_name_field)"
        class="fas fa-check-circle ml-2"
        title="Email verified at {{ guest.email_verified_at | date: 'dd/MM/yyyy, hh:mm' }}"
      ></i>
    </p>

    <ng-container *ngIf="fieldsWithoutCustomFirstLastName.length > 1">
      <table>
        <tr *ngFor="let field of fieldsWithoutCustomFirstLastName; let i = index">
          <ng-container *ngIf="field.definition?.visible && !field.definition.system_field">
            <td>{{ field.definition.label | titlecase }}:</td>
            <td class="pl-5">
              <ng-container [ngSwitch]="field.definition.type">
                <span *ngSwitchCase="formFieldTypes.select">
                  {{ field.value | matchValueWithPossibleFieldValues: field.definition.possible_values | titlecase }}
                </span>
                <span *ngSwitchCase="formFieldTypes.checkbox">
                  {{ field.value | matchValueWithPossibleFieldValues: field.definition.possible_values | titlecase }}
                </span>
                <span *ngSwitchDefault>{{ field.value | titlecase }}</span>
              </ng-container>
            </td>
          </ng-container>
        </tr>
      </table>
    </ng-container>

    <ng-container *ngIf="salesData">
      <h1>Sales Data Placeholder</h1>
    </ng-container>
  </div>
  <div class="card-footer">
    <span class="float-right text-muted">Joined in {{ guest.created_at | date: 'MMM. d' }}</span>
  </div>
</div>
