import { Pipe, PipeTransform } from '@angular/core';
import { GuestCustomFieldsDefinitionPossibleValues } from '../entities/events/guest-custom-fields-definition.entity';

@Pipe({
  name: 'matchValueWithPossibleFieldValues'
})
export class MatchValueWithPossibleFieldValuesPipe implements PipeTransform {
  public transform(value: string | null, possibleFieldValues: GuestCustomFieldsDefinitionPossibleValues[] = []): string {
    console.log('match', value, possibleFieldValues);

    // value is null, return null string
    if (value === null) return '';

    // field has no possible values, return given value
    if (possibleFieldValues.length <= 0) return value;

    const filteredFieldValues = possibleFieldValues.filter(possibleFieldValue => value === possibleFieldValue.value);

    // filtered possible values has no entries, return given value
    if (filteredFieldValues.length <= 0) return value;

    // return first filtered field value label
    return filteredFieldValues[0].label;
  }
}
