<app-table-toolbar>
  <div>
    <h6 *ngIf="tableTitle" class="h3 mb-0 pt-2 pb-1">{{ tableTitle }}</h6>
    <app-search-input
      (searchValue)="onSearch($event)"
      [withCustomLabel]="true"
      *ngIf="searchEnabled && (!selectedRows || selectedRows.length === 0)"
    ></app-search-input>
  </div>

  <ng-content></ng-content>
  <span class="flex-grow-1"></span>

  <!--
  <button
    *ngIf="exportEndpoint && paginatedResponse?.data?.length > 0"
    [columns]="filteredColumns"
    [endpoint]="exportEndpoint"
    [modificationFunction]="exportModificationFunction"
    appExport
    class="mr-2"
    color="accent"
    fileName="export"
    mat-icon-button
    type="button"
  >
    <mat-icon>cloud_download</mat-icon>
  </button>
  -->
  <div class="mr-2" *ngIf="exportEndpoint && paginatedResponse?.data?.length > 0">
    <div class="small text-center text-muted">Export</div>
    <button class="text-center" color="accent" mat-icon-button type="button" (click)="exportTable()">
      <img [src]="'/assets/images/ms_excel_logo.svg'" alt="HEADER_LOGO" style="max-height: 30px" />
    </button>
  </div>

  <div *ngIf="allColumns && favoriteKey">
    <div class="small text-center text-muted">Colums</div>
    <div class="colums-button">
      <button class="text-center border border-muted mt-1" color="accent" mat-icon-button type="button" (click)="showColumnSettingDialog()">
        <i class="fas fa-2x fa-caret-down"></i>
      </button>
    </div>
  </div>
</app-table-toolbar>

<ngx-datatable
  (activate)="onActivate($event)"
  (page)="pageOffset = $event.offset + 1; loadContent.emit({ page: $event.offset + 1, search: search, sorts: sorts })"
  (select)="onSelect($event)"
  (sort)="onSort($event)"
  [columnMode]="ColumnMode.force"
  [count]="paginatedResponse?.meta?.total"
  [externalPaging]="true"
  [externalSorting]="true"
  [footerHeight]="50"
  [headerHeight]="50"
  [limit]="paginatedResponse?.meta?.per_page"
  [loadingIndicator]="loading"
  [offset]="paginatedResponse?.meta?.current_page - 1"
  [rowHeight]="'auto'"
  [rows]="paginatedResponse?.data || []"
  [scrollbarH]="true"
  [selected]="selectedRows"
  [selectionType]="rowsSelectable ? 'checkbox' : false"
  [virtualization]="false"
  class="material expandable"
>
  <ngx-datatable-column
    *ngIf="rowsSelectable"
    [canAutoResize]="false"
    [checkboxable]="true"
    [draggable]="false"
    [headerCheckboxable]="true"
    [resizeable]="false"
    [sortable]="false"
    [width]="30"
  >
  </ngx-datatable-column>

  <ng-container *ngFor="let column of filteredColumns">
    <ngx-datatable-column
      [comparator]="column.type === ColumnVisalizationType.date ? momentDatatableComparator : null"
      name="{{ column.label }}"
      prop="{{ column.name }}"
    >
      <ng-template let-index="index" let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <ng-container *ngFor="let item of (value | isArray) ? value : [value]; index as valueIndex; let last = last">
          <ng-container [ngSwitch]="column.type">
            <ng-container *ngSwitchCase="ColumnVisalizationType.date">
              {{ item | date: 'MMM d y' }}
              {{ item | date: 'hh:mm a' }}
            </ng-container>
            <ng-container *ngSwitchCase="ColumnVisalizationType.badge">
              <app-badge *ngIf="valueIndex < 3 && item != ''" class="mr-1">
                <mat-icon *ngIf="column.icons">{{ column.icons | getmap: item }}</mat-icon>
                <span *ngIf="!column.icons">{{ (column.itemKey ? item[column.itemKey] : item) | truncateString: { characters: 9 } }}</span>
              </app-badge>

              <app-badge *ngIf="valueIndex === 3" class="mr-1" (mouseover)="showBadge = true" (mouseout)="showBadge = false">
                ...
              </app-badge>
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.link">
              <a *ngIf="item" href="{{ item }}" target="_blank"><i class="fa fa-external-link-alt"></i></a>
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.list">
              <span>{{ column.itemKey ? item[column.itemKey] : item }}</span
              ><br *ngIf="!last" />
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.uuid">
              {{ item | truncateString: { characters: 6 } }}
              <a [cdkCopyToClipboard]="item">
                <ng-container>
                  <mat-icon class="small">content_copy</mat-icon>
                </ng-container>
              </a>
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.file">
              <a class="d-block" *ngIf="item" href="{{ (row.attachments | arrayFindByUuid: item)?.signed_url }}" target="_blank">
                {{ (row.attachments | arrayFindByUuid: item)?.filename }}
                <i class="fa fa-external-link-alt"></i
              ></a>
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.statusLight">
              <i
                class="fas fa-circle text-success"
                [class.text-danger]="row[column.name.split('-')[0]]?.set.indexOf(column.itemKey) == -1"
              ></i>
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.iconText">
              <mat-icon *ngIf="column.icons | getmap: row[column.name]">{{ column.icons | getmap: row[column.name] }}</mat-icon>
              <span *ngIf="!(column.icons | getmap: row[column.name])">{{ item }}</span>
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.textMap">
              <span *ngIf="column.icons | getmap: row[column.name]">{{ column.icons | getmap: row[column.name] }}</span>
              <!--              <span *ngIf="!(column.icons | getmap: row[column.name])">{{ column.defaultIcon }}</span>-->
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.iconOnly">
              <mat-icon *ngIf="column.icons | getmap: row[column.name]">{{ column.icons | getmap: row[column.name] }}</mat-icon>
              <mat-icon *ngIf="!(column.icons | getmap: row[column.name])">{{ item }}</mat-icon>
            </ng-container>

            <ng-container *ngSwitchCase="ColumnVisalizationType.textOnly">
              <span *ngIf="column.icons | getmap: row[column.name]">{{ column.icons | getmap: row[column.name] }}</span>
              <!--              <span *ngIf="!(column.icons | getmap: row[column.name])">{{ column.defaultIcon }}</span>-->
            </ng-container>

            <!--
            <ng-container *ngSwitchCase="ColumnVisalizationType.select">
              <mat-form-field>
                <mat-label>{{ column.label }}</mat-label>
                <mat-select
                  (ngModelChange)="updateValue($event, column.name, rowIndex, row)"
                  [multiple]="!!column.select_multiple"
                  [ngModel]="value"
                >
                  <mat-option disabled="">Select</mat-option>
                  <mat-option *ngFor="let select of column.selectable_values" [value]="select.value">{{ select.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container> -->

            <ng-container *ngSwitchDefault>
              <mat-icon *ngIf="column.icons && item">{{ column.icons | getmap: item }}</mat-icon>
              <div
                *ngIf="column.type !== ColumnVisalizationType.iconOnly"
                class="d-flex justify-content-between editable-cell align-items-center"
              >
                <span *ngIf="editingCell.id !== rowIndex + '-' + column.name">
                  {{ item }}
                </span>
                <mat-form-field *ngIf="editingCell.id === rowIndex + '-' + column.name">
                  <input
                    (change)="editingCell.value = $event.target.value"
                    (keyup)="editCellKeyUp($event)"
                    [value]="row[column.name]"
                    autofocus
                    matInput
                    type="text"
                  />
                </mat-form-field>

                <button
                  (click)="editCell($event, column.name, rowIndex, row)"
                  *ngIf="column.editable && editingCell.id !== rowIndex + '-' + column.name"
                  class="edit-button"
                  color="accent"
                  mat-mini-fab
                >
                  <mat-icon>edit</mat-icon>
                </button>
                <button
                  (click)="saveRow($event, row, column.name)"
                  *ngIf="editingCell.id === rowIndex + '-' + column.name"
                  color="primary"
                  mat-button
                >
                  Save
                </button>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
    </ngx-datatable-column>
  </ng-container>
</ngx-datatable>
